import { Row } from "antd";
import React, { memo, useEffect, useState } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import useHttp from "../../hooks/use-http";

const CustomDND = ({
  data,
  setRefresh,
  editApi,
  payloadIdKey,
  grid,
  height,
  component = <></>,
}) => {
  const [images, SetImages] = useState([]);
  const api = useHttp();
  useEffect(() => {
    SetImages(data);
  }, [data]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(images, sourceIndex, targetIndex);
    SetImages(nextState?.map((el, i) => ({ ...el, index: i + 1 })));
    const apiData = { ...editApi };
        const payload = {
          updateArray: nextState?.map((el, i) => ({
            [payloadIdKey]: el?.[payloadIdKey],
            index: i + 1 ,
          })),
        };
       
        api.sendRequest(
          apiData,
          (res) => {
            setRefresh((prv)=>!prv)
          },
          payload,
          "Update Index Successfully."
       
        );

  }

  return (
    <div>
      <Row gutter={[20, 20]}>
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="items"
            boxesPerRow={grid}
            rowHeight={300}
            style={{
              height: `${Math?.ceil(images?.length / 3) * height}px`,
              width: "100%",
            }}
          >
            {images?.map((item, index) => (
              <GridItem key={index} className="p-4 ">
                {component(item)}
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      </Row>
    </div>
  );
};

export default memo(CustomDND);
