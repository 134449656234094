import React from "react";
import HomeCountry from "./HomeCountry";
const HomePage = () => {
  return (
    <>
      <HomeCountry />
    </>
  );
};

export default HomePage;
