import { Card, Col, Divider, Row, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useHttp from "../../../../hooks/use-http";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import { apiGenerator } from "../../../../util/functions";
import { ROLES } from "../../../../util/Route";
const BeachDetails = [
  //       { id: "allTerrainVehicles", label: "All-Terrain Vehicles" },
  //       { id: "panicTable", label: "Panic Table" },
  //       { id: "partyScene", label: "Party Scene" },
  //       { id: "swimming", label: "Swimming" },
  //       { id: "loungeChairRental", label: "Lounge Chair Rental" },
  //       { id: "boats", label: "Boats" },
  {
    title: "Amenities",
    items: [
      { id: "ATM", label: "ATM" },
      { id: "barbecue", label: "Barbecue" },
      { id: "boatRamps", label: "Boat Ramp" },
      { id: "dogs", label: "Dogs" },
      { id: "entryFee", label: "Entry Fee" }, //new
      { id: "firePits", label: "Fire Pits" },
      { id: "hidden", label: "Hidden" },
      { id: "lifeguard", label: "Lifeguard" },
      { id: "marineLife", label: "Marine Life" },
      { id: "pier", label: "Pier" }, //new
      { id: "playground", label: "Playground" },
      { id: "restRooms", label: "Restrooms" },
      { id: "shops", label: "Shops" }, //new
      { id: "showers", label: "Showers" },
      { id: "wifi", label: "WiFi" },
      { id: "picnicTable", label: "Picnic Table" }, //new
    ],
  },
  {
    title: "Activities",
    items: [
      { id: "bikePaths", label: "Bike Paths" },
      { id: "camping", label: "Camping" },
      { id: "clubsPartyScene", label: "Clubs Party Scene" }, //new
      { id: "fishing", label: "Fishing" },
      { id: "hiking", label: "Hiking" },
      { id: "nude", label: "Nude" },
      { id: "paddleBoards", label: "Paddleboarding" },
      { id: "scubaDiving", label: "Scuba Diving" },
      { id: "snorkeling", label: "Snorkeling" },
      { id: "surfing", label: "Surfing" },
      { id: "tours", label: "Tours" }, //new
      { id: "volleyball", label: "Volleyball" },
      { id: "cave", label: "Cave" },
    ],
  },
  {
    title: "Rentals",
    items: [
      { id: "ATV", label: "ATV" },
      { id: "bikeAccess", label: "Bikes" },
      { id: "boats", label: "Boat" }, //new
      { id: "fishingBoats", label: "Fishing Boats" },
      { id: "jetSkiing", label: "Jet Skiing" },
      { id: "kayakingCanoeing", label: "Kayaking Canoeing" },
      { id: "beachMatRental", label: "Mat Rentals" },
      { id: "sailing", label: "Sailing" }, //new
      { id: "speedBoats", label: "Speed Boats" },
      { id: "umbrellaRental", label: "Umbrella Rental" },
      { id: "waterSkiing", label: "Waterskiing" },
      { id: "windsurfing", label: "Windsurfing" },
      { id: "yachts", label: "Yachts" },
    ],
  },
  {
    title: "Food",
    items: [
      { id: "bars", label: "Bars" },
      { id: "foodSnacks", label: "Food Snacks" }, //new
      { id: "beachVendors", label: "Local Beach Vendor" },
      { id: "lounge", label: "Lounge" }, //new
      { id: "restaurant", label: "Restaurants" },
    ],
  },
  {
    title: "Access",
    items: [
      { id: "bikeRacks", label: "Bike Racks" },
      { id: "carAccess", label: "Car Access" },
      { id: "disability", label: "Disabled Access" },
      { id: "footAccess", label: "Foot Access" },
      { id: "freeParking", label: "Parking - Free" },
      { id: "paidParking", label: "Parking - Paid" },
      { id: "rvParking", label: "Parking - RV Parking" },
      { id: "streetParking", label: "Parking - Street" },
      { id: "busStop", label: "Public Bus Stop" },
      { id: "stairsToBeach", label: "Stairs to Beach" },
      { id: "boatAccess", label: "Boat Access" },
    ],
  },
  {
    title: "Features",
    items: [
      { id: "blueFlag", label: "Blue Flag" },
      { id: "jellyfish", label: "Jellyfish" }, //new
      { id: "pebbles", label: "Pebbles" }, //new
      { id: "riptide", label: "Riptide" }, //new
      { id: "rocky", label: "Rocky" }, //new
      { id: "sharks", label: "Sharks" }, //new
      { id: "lgbtqplus", label: "LGBTQ+" }, //new
    ],
  },
];
const Amenities = ({ beachData, identity, notdisabled = false }) => {
  // console.log(beachData);
  // const data = beachData?.beachCategory;
  const [BeachDataPoints, setBeachDataPoints] = useState(null);
  const [refresh, setRefresh] = useState(true);

  const [activityStatus, setActivityStatus] = useState({});
  const API = useHttp();
  const handleToggle = (itemId) => {
    const payload = { [itemId]: !!!activityStatus?.[itemId] };
    if (BeachDataPoints?.id && beachData?.id) {
      payload.AllBeachId = beachData?.id;
      const UpdateDataPoint = apiGenerator(
        CONSTANTS.API.BeachDataPoints.update,
        {
          id: BeachDataPoints?.id,
        }
      );
      API.sendRequest(
        UpdateDataPoint,
        (res) => {
          setActivityStatus((prevStatus) => ({
            ...prevStatus,
            [itemId]: !!!prevStatus?.[itemId],
          }));
          // setRefresh((prev) => !prev);
        },
        payload,
        `Data Point Updated successfully`
        // null,
        // () => {
        //   setRefresh((prev) => !prev);
        // }
      );
    } else {
      if (beachData?.id) {
        const CreateDataPoint = apiGenerator(
          CONSTANTS.API.BeachDataPoints.create
        );
        payload.AllBeachId = beachData?.id;
        API.sendRequest(
          CreateDataPoint,
          (res) => {
            setActivityStatus((prevStatus) => ({
              ...prevStatus,
              [itemId]: !!!prevStatus?.[itemId],
            }));
            setRefresh((prev) => !prev);
          },
          payload,
          `Data Point Updated successfully`
          // null,
          // () => {
          //   setRefresh((prev) => !prev);
          // }
        );
      }
    }
  };
  useEffect(() => {
    if (beachData?.id) {
      const BasicDetailsAPI = apiGenerator(
        CONSTANTS.API.BeachDataPoints.getOne,
        {
          AllBeachId: beachData?.id,
        }
      );
      API.sendRequest(BasicDetailsAPI, (res) => {
        setActivityStatus(res?.data?.[0]);
        setBeachDataPoints(res?.data?.[0]);
      });
    }
  }, [refresh, beachData]);

  return (
    <>
      <Card bordered className="m-4">
        <Row gutter={[32, 32]}>
          {BeachDetails?.map((category) => (
            <Col
              key={category?.title}
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              xxl={4}
              style={{ padding: "5px" }}
            >
              <Card className=" w-full h-full rounded-lg shadow-lg ">
                <Typography.Title
                  level={4}
                  className="!py-1 !my-1 flex items-center justify-center"
                >
                  {category?.title}
                </Typography.Title>
                <Divider className="my-2" />
                {category?.items?.map((item) => (
                  <Row key={item?.id} className="mb-2">
                    <Col xs={18} sm={14} md={16} lg={18}>
                      <p>{item?.label}</p>
                    </Col>
                    <Col xs={6}>
                      <Switch
                        id={item?.id}
                        loading={API.isLoading}
                        disabled={
                          ![
                            ROLES.ADMIN,
                            ROLES.DATA,
                            ROLES.IMAGE,
                            ROLES.INTERN,
                            ROLES.SEO,
                            ROLES.SUPER_ADMIN,
                            ROLES.SUPERUSER,
                            ROLES.WRITER,
                          ].some((el) => el === identity) || notdisabled
                        }
                        checked={!!activityStatus?.[item?.id]}
                        onChange={() => handleToggle(item?.id)}
                      />
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
};

export default Amenities;
