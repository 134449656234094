import { Empty, Select, Spin } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import useHttp from '../../hooks/use-http';
import CONSTANTS from '../../util/constant/CONSTANTS';

const SelectWithSearch = ({
    // value, // Value from <Form.Item>
    onChange, // onChange from <Form.Item>
    data = {}, // Additional custom props
    formData = {}
}) => {
    const [options, setOptions] = useState([]); // State to store options
    const [loading, setLoading] = useState(false); // State to show loading status
    const [searchValue, setSearchValue] = useState(''); // State for the current search input
    const debounceTimeout = useRef(null); // Ref to store the debounce timeout
    const api = useHttp();

    // Initialize options from data
    useEffect(() => {
        setOptions((prev) => [...prev, ...(data?.option || [])]);

    }, [data?.option]);
    useEffect(() => {
        if (formData && Object?.keys(formData)?.length > 0) {
            if (formData?.AllBeach?.name) {
                const firstWord = formData?.AllBeach?.name.split(" ")[0];
                fetchOptions(firstWord)
            }
        }
    }, [])


    // Fetch options from the API
    const fetchOptions = async (searchQuery) => {
        // console.log(searchQuery, 'hey')
        try {
            const beachApi = { ...CONSTANTS.API.Beach.getAll };
            api.sendRequest(
                beachApi,
                (res) => {
                    const beachList = res?.data?.rows?.map((i) => ({
                        ...i,
                        label: i?.name,
                        value: i?.id,
                        id: i?.id,
                    }));
                    setOptions(beachList);
                },
                { searchQuery, limit: 1000 },
                null,
                () => {
                    setOptions([]);
                }
            );
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };
    // Handle search input with debounce
    const handleSearch = (value) => {

        setLoading(pr => {
            return !pr
        });
        setSearchValue(value);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (value) {
                setOptions([]);
                fetchOptions(value);
            } else {
                setOptions([]); // Clear options if search is cleared
            }
        }, 500); // Adjust debounce duration as needed
    };

    // Clean up debounce timeout
    useEffect(() => {
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);
    // Render Select with proper bindings
    return (
        <Select
            showSearch
            mode={data?.mode}
            loading={api.isLoading}
            placeholder={data.placeholder || ''}
            notFoundContent={api.isLoading ? <Spin className='flex items-center justify-center' size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />} // Show a loading spinner
            onSearch={handleSearch} // Debounced search
            filterOption={false} // Disable default filter
            allowClear
            defaultValue={data?.defaultValue ?? formData?.[data?.name]}
            // value={74974} // Value bound to <Form.Item>
            onChange={onChange} // Notify <Form.Item> of changes
            // filterOption={(inputValue, option) => {
            //     const value = option?.children?.props?.children[0]?.props
            //         ?.children
            //         ? `${option?.children?.props?.children[0]?.props?.children}`
            //         : `${option?.children}`;
            //     return value
            //         ?.toLowerCase()
            //         ?.includes(inputValue?.toLowerCase());
            // }}
            options={options}
            optionRender={(option) => {
                return (
                    <>
                        <>
                            <p className='text-lg'>{option?.data?.label}</p>
                            <p className='text-sm text-slate-500'>{option?.data?.city?.name}, {option?.data?.state?.name}, {option?.data?.country?.name}</p>
                        </>
                    </>
                )
            }}
        />
        //     {options.map((item) => (
        //         <Select.Option key={`option_${item.id}`} value={item.value}>
        //             {item.label || item.value}
        //         </Select.Option>
        //     ))}
        // </Select>
    );
};

export default SelectWithSearch;
